/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    title: {
      default: {
        id: 'header.title.default',
        defaultMessage: '{name} is looking for new talent!'
      },
      referral: {
        id: 'header.title.referral',
        defaultMessage: "Welcome to {name}'s referral program!"
      },
      internal : {
        id: 'header.title.internal',
        defaultMessage: "Take a chance and grow! Look for new job opportunities in {name}"
      }
    },
    button: {
      positions: {
        id: 'header.button.positions',
        defaultMessage: 'View positions'
      },
      referral: {
        id: 'header.button.referral',
        defaultMessage: 'View program'
      },
    }
  }
});
