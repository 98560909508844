/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  referral: {
    links: {
      title: {
        id: 'referral.links.title',
        defaultMessage: 'Share it via'
      },
      link: {
        id: 'referral.links.link',
        defaultMessage: 'This is your referral link, start sharing it with your friends & family!'
      },
      copy: {
        id: 'referral.links.copy',
        defaultMessage: 'Or copy this link and share it anywhere 🚀'
      },
      location: {
        id: 'referral.links.location',
        defaultMessage: 'Select your {location}'
      }
    },
    error: {
      fields: {
        id: 'referral.error.fields',
        defaultMessage: '* You must fill all the fields!'
      }
    },
    button: {
      generateLink: {
        id: 'referral.button.generateLink',
        defaultMessage: 'GENERATE MY LINK'
      },
      copied: {
        id: 'referral.button.copied',
        defaultMessage: 'copied!'
      }
    },
    channel: {
      message: {
        sms: {
          id: 'jobBoard.channel.message.sms',
          defaultMessage: "Hi Emi! I want to apply to work as {position} at {company}, I have the code: {code}"
        },
        whatsapp: {
          id: 'jobBoard.channel.message.whatsapp',
          defaultMessage: "Hi Emi! I want to apply to work as {position} at {company}, I have the code: {code}"
        }
      }
    }
  }
});
