import React, { Component } from "react";
import { Element } from "react-scroll";
import "./referrals.css";
import "./sticky.css";
import "../../css/normalize.css";
import "../../css/grid.css";
import BtnLoad from "../Buttons/BtnLoad";
import { Listing } from "../../strings";
import { injectIntl } from "react-intl";
import messages from "./messages";
import Links from "./links";
import ReactMarkdown from "react-markdown";
import DropdownInput from './DropdownInput';

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referralData: {},
      errorFields: false,
      scrollingLock: false,
      copy: false
    };
    this.handleLink = this.handleLink.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollHelp = this.handleScrollHelp.bind(this)
  }
  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    if (window.scrollY > 400) {
      this.setState({
        scrollingLock: true,
      });
    } else if (window.scrollY < 350) {
      this.setState({
        scrollingLock: false,
      });
    }
  }
  handleScrollHelp() {
    let element = document.getElementById("referral-help-container-id")
    element.scrollIntoView({ behavior: "smooth" })
  }
  handleCopy = () => {
    this.setState({ copy: true });
  };
  handleLink = (link) => {
    // window.location.href = link;
    window.open(
      link,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  generateLink = () => {
    const { referralData } = this.state;
    const { company } = this.props;

    const error = [];
    company.referral.employeeFields.forEach((k) => {
      if (!referralData[k.name]) error.push(k.label || k.name)
    });

    if (company.subsidiaryId == 67 && !referralData.facility) {
      this.setState({ errorFields: true });
    } else if (error.length) {
      this.setState({ errorFields: error.join(", ") });
    } else if (this.props.onGenerateLink) {
      this.setState({ errorFields: false });
      this.props.onGenerateLink(referralData);
    } else {
      this.setState({ errorFields: false });
    }
  };

  onInputchange = (event) => {
    const { referralData } = this.state;
    
    this.setState({
      referralData: {
        ...referralData,
        [event.target.name]: event.target.value
      }
    });
  };

  render() {
    // si isLoading es = true significa que todavia no trajo los datos para mostrarlos
    const { formatMessage } = this.props.intl;
    let { isLoading, company } = this.props;
    const { errorFields, referralData } = this.state;

    return (
      <div className="section">
        <Element name={Listing} />
        <div className="referral-title-container" style={{backgroundColor: company.style.referralTitleBackgroundColor}}>
          <div><h2 className="referral-title-text">{company.referral.title}</h2></div>
          <div><p className="referral-subtitle-text">
            {company.referral.subTitle}
          </p></div>

        </div>
        <div className="referral-form-container">
          {isLoading ? (
            <BtnLoad />
          ) : this.props.code ? (
            <Links
              code={this.props.code}
              query={this.props.query}
              company={company}
            />
          ) : (
            <div className="referral-form-control">
              <p className="referral-form-title"> {company.referral.formTitle}</p>
              {company.referral.employeeFields.map((field, i) => (
                <React.Fragment key={i}>
                  {field.dropdown ? (
                    <DropdownInput
                      name={field.name}
                      label={field.label}
                      emoji={field.emoji}
                      options={field.dropdown}
                      value={referralData[field.name]}
                      onChange={this.onInputchange}
                      isInputType={field.inputText}
                    />
                  ) : (
                    <div className="referral-form-control-input-container">
                      {field.emoji && <img src={field.emoji} className="referral-form-control-input-emoji" />}
                      <input
                        className="referral-form-control-input"
                        type="text"
                        id={field.name}
                        name={field.name}
                        placeholder={field.label}
                        onChange={this.onInputchange}
                        maxLength={field.maxLength || undefined}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
              {company.referral.help ? 
              <button className="referral-form-help-button" onClick={this.handleScrollHelp}>
                <p className="referral-form-help-text">{company.referral.help.title}</p>
              </button> : null }

              <p className="referral-form-warning-text">{company.referral.warning}</p>

              <div style={{ textAlign: "center", marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {company.referral.generateLinkMessage ? (
                  <div>{company.referral.generateLinkMessage}</div>
                ) : null}
                <button
                  onClick={() => this.generateLink()}
                  type="button"
                  className="referral-form-button"
                  style={
                    this.props.style && this.props.style.color
                      ? { backgroundColor: this.props.style.color }
                      : {}
                  }
                >
                  {company.referral.generateLinkButtonImg ? <div className="referral-form-button-icon-container"><img className="referral-form-button-icon" src={company.referral.generateLinkButtonImg} /></div> : null}
                  <div className="referral-form-button-text-container">{formatMessage(messages.referral.button.generateLink)}</div>


                </button>
                {errorFields ? (
                  <div style={{ color: "red" }}>
                    {formatMessage(messages.referral.error.fields)}: {errorFields}
                  </div>
                ) : null}
              </div>
              <div className="referral_conditions_container">
                <ReactMarkdown className="referral_conditions">
                  {company.referral.conditions}
                </ReactMarkdown>
              </div>
            </div>
          )}
        </div>

        <div className="referral-instructions-container">
          <div className="referral-instructions-title">
            <p className="referral-instructions-title-text">{company.referral.instructionsTitle}</p>
          </div>
          <div className="referral-instructions-subtitle">
            <p className="referral-instructions-subtitle-text">{company.referral.instructionsSubTitle}</p>
          </div>
          <div className="referral-instructions-steps">
            {company.referral.instructions.map((step, i) => {
              return (
                <div className="referral-instructions-step" key={i}>
                  <div className="referral-instructions-step-title">{step.title}</div>
                  <div className="referral-instructions-step-text">{step.text}</div>
                </div>
              )
            })}
          </div>
            {company.referral.attention ? 
          <div className="referral-instructions-attention">
            <div className="referral-instructions-attention-title">{company.referral.attention.title}</div>
            {company.referral.attention.texts.map(t=>{
              return (
                <div className="referral-instructions-attention-text">{t.text}{t.link ? <a href={t.link.href} target="_blank">{t.link.label}</a> : null}</div>
              )
            })}
          </div> : null }
        </div>
        {company.referral.help ? 
        <div className="referral-help-container" id="referral-help-container-id">
          <div className="referral-help-title">{company.referral.help.title}</div>
          <div className="referral-help-subtitle">{company.referral.help.subTitle}</div>
          <div className="referral-help-instructions">{company.referral.help.instructions}</div>
          <img className="referral-help-image" src={company.referral.help.image} />
        </div> : null}
        {/* <!-- END LISTING --> */}
      </div>
    );
  }
}

export default injectIntl(Referral);
