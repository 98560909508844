import React from "react";
import "../../App.css";
import BtnLoad from "../Buttons/BtnLoad";
import FacebookBtn from "./FacebookBtn.js";

import "./SinglePosition.css";
import WhatsappBtn from "./WhatsappBtn";


const workingHoursDict = {
  FULL_TIME: "Tiempo Completo",
  PART_TIME: "Tiempo parcial"
}


export default ({company, applyPosition, openCloseModal, handleFacebookLink, handleWhatsappLink, applicationChannel,
modalOpened, queryParams}) => { 
  return (
      <>
        {applyPosition ? (
          <>
            <div className="single-position-main-section">
              <div className="single-position-name">{applyPosition.title}</div>
              <div className="single-position-application-container">
                <div className="single-position-application-title">
                  ¡Empieza una conversación con Emi para aplicar!
                </div>
                <div className="single-position-application-buttons">
                  <WhatsappBtn openCloseModal={openCloseModal}/>
                  <FacebookBtn openCloseModal={openCloseModal}/>
                </div>
              </div>
            </div>
            <div className="single-position-details-section">
              <div className="single-position-basic-information">
                <div className="single-position-basic-information-element">
                  <div className="single-position-basic-information-element-title">Jornada</div>
                  <div className="single-position-basic-information-element-position">
                    {workingHoursDict[applyPosition.workingHours]}
                  </div>
                </div>
                <div className="single-position-basic-information-element">
                  <div className="single-position-basic-information-element-title">Ciudad</div>
                  <div className="single-position-basic-information-element-position">
                    {applyPosition.city}
                  </div>
                </div>
                {applyPosition.brand ? 
                <div className="single-position-basic-information-element">
                  <div className="single-position-basic-information-element-title">Marca</div>
                  <div className="single-position-basic-information-element-position">
                    {applyPosition.brand}
                  </div>
                </div>
                : null }
              </div>

              <div className="single-position-description">
                <div className="single-position-description-title">
                  Descripción del puesto
                </div>
                <div className="single-position-description-position">
                  {applyPosition.description}
                </div>
              </div>
              {company.about ? <div className="single-position-about-company">
                <div className="single-position-about-company-title">
                  {company.about.title}
                </div>
                <div className="single-position-about-company-position">
                  {company.about.texts.map(t=>{
                    return (
                      <div>{t.text}</div>
                    )
                  })}
                </div>
              </div> : null}
              {company.benefits ? <div className="single-position-benefits">
                <div className="single-position-benefits-title">{company.benefits.title}</div>
                <div className="single-position-benefits-position">
                {company.benefits.benefitsSinceFirstDay? <>
                {company.benefits.benefitsSinceFirstDay.title ? <div className="single-position-benefits-subtitle">{company.benefits.benefitsSinceFirstDay.title}</div> : null}
                {company.benefits.benefitsSinceFirstDay.texts.map(t=>{
                  return (
                    <div>
                      {`• ${t.text}`}
                    </div>
                  )
                })}
                </> : null}
                  <br />
                  <br />
                  {company.benefits.benefitsFromStart? <>
                {company.benefits.benefitsFromStart.title ? <div className="single-position-benefits-subtitle">{company.benefits.benefitsFromStart.title}</div> : null}
                {company.benefits.benefitsFromStart.texts.map(t=>{
                  return (
                    <div>
                      {`• ${t.text}`}
                    </div>
                  )
                })}
                </> : null}
                </div>
              </div> : null}
            </div>
            <div className="single-position-faq-section">
              <div className="single-positon-faq-section-title">
                Aplicar a {company.company_name} con Emi
              </div>
              <div className="single-position-faq">
                <div className="single-position-faq-title">
                  ¿Cómo será el proceso de aplicación vía Emi?
                </div>
                <div className="single-position-faq-text">
                  El proceso de aplicación consiste en una conversación con Emi. Emi te hará preguntas para ir avanzando en la aplicación y te dará soporte 24/7 en cuanto al estado y avances de tu aplicación.
                </div>
              </div>
              <div className="single-position-faq">
                <div className="single-position-faq-title">
                  ¿Se puede modificar la aplicación una vez completado o durante
                  el flujo conversacional?
                </div>
                <div className="single-position-faq-text">
                  No se puede modificar la aplicación una vez completada. Al finalizar la conversación, recibirás novedades de Emi sobre el status de tu aplicación y los próximos pasos. 
                </div>
              </div>
              <div className="single-position-faq">
                <div className="single-position-faq-title">
                  ¿Cómo protegemos tu información personal?
                </div>
                <div className="single-position-faq-text">
                  La protección de datos brindados durante el proceso es crucial para nosotros. Es por eso que protegemos los datos sensibles de todos los candidatos a través de nuestra certificación internacional <span><a href="https://www.emilabs.ai/blog/emis-commitment-to-security-soc2">SOC2</a></span> que audita el manejo de la información y procesos.
                </div>
              </div>
              <div className="single-position-faq">
                <div className="single-position-faq-title">
                  ¿Cuáles son los canales recomendados para aplicar?
                </div>
                <div className="single-position-faq-text">
                  Los canales recomendados y disponibles para aplicar son WhatsApp y Facebook.
                </div>
              </div>
            </div>
            <div className="single-position-application-footer-container">
              <div className="single-position-application-footer-title-section">
                <div className="single-position-application-title">
                  ¡Empieza una conversación con Emi para aplicar!
                </div>
                <div className="single-position-application-text">
                  Nuestro Chatbot Emi te va a ayudar en todo tu proceso de
                  aplicación, elige por cual canal quieres aplicar, y te
                  redirigirá a la conversación.
                </div>
              </div>
              <div className="single-position-application-container">
                <div className="single-position-application-buttons">
                  <WhatsappBtn openCloseModal={openCloseModal}/>
                  <FacebookBtn openCloseModal={openCloseModal}/>
                </div>
              </div>
            </div>
            {modalOpened ? (
              <div className="modal-section">
                <div className="modal-apply-position">
                  <div className="modal-apply-close">
                    <button
                      className="modal-apply-close-button"
                      onClick={openCloseModal}
                    >
                      <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/close.png" />
                    </button>
                  </div>
                  {company.modalApplyImg ? (
              <div className="modal-image-container">
                <img src={company.modalApplyImg} />
              </div>
              ) : null}
                  <div className="modal-apply-text-message">Vas a Aplicar</div>
                  <div className="modal-apply-position-name">
                    {applyPosition.title}
                  </div>
                  <div className="modal-apply-chat-message">
                    Queremos ayudarte a aplicar rápidamente a esta posición
                    para esto tenemos un proceso diseñado para poder evaluar
                    mejor tu perfil con Emi nuestro chatbot que te acompañará
                    durante tu proceso.
                  </div>
                  <div className="modal-apply-single-position-application-channel">
                    <div className="modal-apply-select-channel-text">
                      Vas a aplicar a través de una conversación en
                    </div>
                    <div className="">
                      {applicationChannel == "Whatsapp" ? (
                        <>
                          <div className="modal-apply-single-position-selected-channel">
                            <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/whatsapp.svg" />
                            <div
                              className="modal-apply-single-position-selected-channel-text"
                              style={{ color: "#128C7E" }}
                            >
                              {applicationChannel}
                            </div>
                          </div>
                          <button
                            className="modal-apply-button"
                            style={{ background: "#128C7E" }}
                            onClick={() =>
                              handleWhatsappLink(
                                company,
                                queryParams,
                                applyPosition
                              )
                            }
                            value="Whatsapp"
                          >
                            Enviar mensaje a Emi
                            <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/whatsapp-white.svg" />
                          </button>
                        </>
                      ) : applicationChannel == "Facebook" ?  (
                        <>
                          <div className="modal-apply-single-position-selected-channel">
                            <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/facebook-messenger.svg" />
                            <div
                              className="modal-apply-single-position-selected-channel-text"
                              style={{ color: "#2F6DB3" }}
                            >
                              {applicationChannel}
                            </div>
                          </div>
                          <button
                            className="modal-apply-button"
                            style={{ background: "#2F6DB3" }}
                            onClick={() =>
                              handleFacebookLink(
                                company,
                                queryParams,
                                applyPosition
                              )
                            }
                            value="Facebook"
                          >
                            Enviar mensaje a Emi
                            <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/facebook-messenger-white.svg" />
                          </button>
                        </>
                      ): null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : <BtnLoad/>}
      </>
    );
  }

