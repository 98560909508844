import React from "react";

export default ({ value, onChange, title, elements, dropdownEnabled, enableDropdown }) => {
  return (
    <div className="dropdown-sub-filter">
      <div className="select-selected" onClick={enableDropdown}>
        <div className="city-dropdown-filter-element-title">
          {`${!value ? title : value}`}
        </div>
        <div>
          <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/arrow-down-2.svg" />
        </div>
      </div>
      <div className={`select-items ${!dropdownEnabled ? "select-hide" : null}`}>
        <div className="city-dropdown-filter-element-option"
          onClick={() => onChange("")}
        >

        </div>
        {elements && elements.map((e) => {
          return (
            <div key={e} className="city-dropdown-filter-element-option"
              onClick={() => onChange(e)}
            >
              {e}
            </div>
          );
        })}
      </div>
    </div>
  )
}