import React, { Component, Fragment } from "react";
import "./Footer.css";
import emi_logo_footer from "../../img/emi_logo_footer.svg";
import {
  ModalsPoliticaDeConfidencialidad,
  ModalsPreguntasFrecuentes
} from "../Modals";
import { handleScrollListing } from "../Buttons/handleScroll";
import { injectIntl } from 'react-intl';
import messages from './messages';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalPdc: false,
      ModalPf: false
    };
    this.OpenModalPdc = this.OpenModalPdc.bind(this);
    this.OpenModalPf = this.OpenModalPf.bind(this);

  }

  OpenModalPdc = () => {
    this.setState({ ModalPdc: true });
  };
  CloseModalPdc = () => {
    this.setState({ ModalPdc: false });
  };

  OpenModalPf = () => {
    this.setState({ ModalPf: true });
  };
  CloseModalPf = () => {
    this.setState({ ModalPf: false });
  };

  handleLink = (link) => {
    // window.location.href = link;
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );
  };
  render() {
    const { formatMessage,locale } = this.props.intl;
    let { ModalPdc, ModalPf } = this.state;
    let { OpenModalPdc, OpenModalPf, CloseModalPdc, CloseModalPf } = this;

    return (
      <Fragment>
        <footer className="site-footer" style={this.props.style && this.props.style.color? {background: this.props.style.color}:null}>
          {locale === 'es'? (
            <div className="more-info">
              <h3 className="more-info-title">{formatMessage(messages.footer.moreInfo.title)}:</h3>
              <button onClick={OpenModalPdc}>
                &lt;{formatMessage(messages.footer.moreInfo.privacy)}&gt;
              </button>
              <button onClick={OpenModalPf}>&lt;{formatMessage(messages.footer.moreInfo.faq)}&gt;</button>
            </div>
          ) :
          <div className="more-info">
            <h3 className="more-info-title">{formatMessage(messages.footer.moreInfo.title)}:</h3>
            <button onClick={ () => {this.handleLink('https://www.emilabs.ai/privacy/')}}>
              &lt;{formatMessage(messages.footer.moreInfo.privacy)}&gt;
            </button>
          </div>
        }

          <div className="site-data">
            <div className="site-credit">
              <span>Powered by</span> <img src={emi_logo_footer} alt="EMI" />
            </div>
            <div className="site-copy">
              {formatMessage(messages.footer.rights, {year: (new Date()).getFullYear()})}
            </div>
          </div>
        </footer>
        {ModalPdc ? (
          <ModalsPoliticaDeConfidencialidad
            onClose={() => {
              CloseModalPdc();
              handleScrollListing();
            }}
            onCloseButton={CloseModalPdc}
            show={ModalPdc}
          />
        ) : null}
        {ModalPf ? (
          <ModalsPreguntasFrecuentes
            onClose={() => {
              CloseModalPf();
              handleScrollListing();
            }}
            onCloseButton={CloseModalPf}
            show={ModalPf}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default injectIntl(Footer);
