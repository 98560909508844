import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import endpoints from "./endpoints";

import CompanyLanding from './containers/CompanyLanding/'
import CompanySinglePosition from './containers/SinglePosition/'
import { injectIntl } from "react-intl";
import messages from './messages';

const HOME_PATH = '/'
const JOB_BOARD_LANDING = '/apply'
const config = require('./config');
const apiServer = config.params.API_SERVER;
const vacanciesServer = config.params.VACANCIES
const subsidiaryServer = config.params.SUBSIDIARY_SERVER;

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      listing: null,
      company: {},
      queryParams: {},
      allWorkSchedules: [],
      allBrands: [],
      allPositions: [],
      allLocations: [],
      allStates: [],
      vacancyListingDict: {},
      companyPath: null
    };
    this.handleSmsLink = this.handleSmsLink.bind(this);
    this.handleWhatsappLink = this.handleWhatsappLink.bind(this);
    this.handleFacebookLink = this.handleFacebookLink.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this)
    this.handleCityUpdates = this.handleCityUpdates.bind(this)
  }



  async componentDidMount() {
    const state = window.location.search
    let path = window.location.pathname;
    const queryString = require('query-string');
    const parsed = queryString.parse(state.substring(1));
    const companyPath = path.substring(1).split("/")[0]
    const company = {...endpoints[companyPath]};
    let listing;
    let brandsList = [];
    let positionsList = [];
    let locationsList = [];
    let statesList = []
    let workSchedulesList = []
    let vacancyListingDict = {}
    let positions = []


    if (path === HOME_PATH || !company) {
      return window.location.href = 'https://www.emilabs.ai/es/?noredirect'
    }

    this.props.changeLanguage(company.language)
    if (company.vacancies) {

      try {
        const result = await fetch(`${vacanciesServer}/search?subsidiaryId=${company.subsidiaryId}`)
        const resultJson = await result.json()
        positions = [...resultJson.results]

        for (let i = 0; i < positions.length; i++) {
          let tags = positions[i].tags
          let vacancyId = positions[i].postingId
          vacancyListingDict[vacancyId] = {
            positionListingId: positions[i].id,
            positionDescription: positions[i].description
          }

          // positions[i].info = company.positionsDescriptions[positions[i].positionId]
          if (!locationsList.includes(positions[i].city)) {
            locationsList.push(positions[i].city)
          }
          if (!statesList.includes(positions[i].state)) {
            statesList.push(positions[i].state)
          }
          if (!positionsList.includes(positions[i].position)) {
            positionsList.push(positions[i].position)
          }
          if (!workSchedulesList.includes(positions[i].workSchedule)) {
            workSchedulesList.push(positions[i].workSchedule)
          }
          if (tags && tags.length > 0) {
            for (let j = 0; j < tags.length; j++) {
              if (tags[j].name && tags[j].name == 'brand') {
                let brands = tags[j].value
                positions[i].brand = brands
                for (let k = 0; k < brands.length; k++) {
                  if (!brandsList.includes(brands[k])) {
                    brandsList.push(brands[k])
                  }
                }
              }

            }
          }

        }
      }
      catch (e) {
        console.log(e)
      }
      if (company.referral && company.referral.employeeFields) {
        let localCompanyReferralFields = [...company.referral.employeeFields]

    for (let i=0 ; i < company.referral.employeeFields.length ; i++){
      let e = {...company.referral.employeeFields[i]}
      if (e.dropdown && e.dropdown == 'stateSubsidiaryLocations'){
        try{
          const subsidiaryLocationsFetch = await fetch(`${subsidiaryServer}/${company.subsidiaryId}/locations`)
          const subsidiaryLocations = await subsidiaryLocationsFetch.json()
          e.dropdown = subsidiaryLocations
          localCompanyReferralFields = [...localCompanyReferralFields.slice(0,i),e,...localCompanyReferralFields.slice(i+1)]
        }
        catch(e){
          console.log(e)
        }
        
      }
    }
    company.referral = {...company.referral, employeeFields: localCompanyReferralFields}
  }
    
      this.setState({
        company: company,
        companyPath: companyPath,
        listing: positions,
        allBrands: brandsList.sort(),
        allLocations: locationsList.sort(),
        allPositions: positionsList.sort(),
        allWorkSchedules: workSchedulesList.sort(),
        allStates: statesList.sort(),
        vacancyListingDict: vacancyListingDict
      })
    }
    else {

        this.setState({
          company: company,
          listing: company.listings,
        })
    }
    this.setState({
      queryParams: parsed,
      isLoading: true
    });
  }

  handleCityUpdates = (state) => {
    const { listing } = this.state
    const locationsList = []
    for (let i = 0; i < listing.length; i++) {
      if (state.toLowerCase() === listing[i].state.toLowerCase()) {
        if (!locationsList.includes(listing[i].city)) {
          locationsList.push(listing[i].city)
        }
      }
      this.setState({
        allLocations: locationsList.sort()
      })

    }
  }


  handleSmsLink = (company, query, applyPosition) => {
    const { formatMessage } = this.props.intl;
    let eref = "CompanyLanding";
    let encode = {
      sourcing: {
        builder: "career_page",
        redirectedFromUrlCategory: "company landing",
        referral: {},
      },
      application: {
        positionId: applyPosition.positionId
      },
    };
    let merge_to_eref2 = "";
    let eref2 = "";
    if (query.builder) {
      encode.sourcing.builder = query.builder;
    }
    if (query.internalCandidate) {
      encode.application.isInternalCandidate = true;
    }
    if (query.job_board) {
      eref = eref + "+" + query.job_board;
      encode.sourcing.jobBoardListingOrigin = query.job_board;
    } else if (query.eref) {
      eref = query.eref;
    }
    if (query && query.campaign) {
      eref = eref + "+" + query.campaign;
      encode.sourcing.campaignName = query.campaign;
    }
    if (query.source) {
      // eref = eref + "+" + query.campaign
      encode.sourcing.source = query.source;
    }
    if (query.content) {
      encode.sourcing.content = query.content;
    }
    if (query.isIncentivized == "true") {
      encode.sourcing.isIncentivized = true;
    }
    if (query.isIncentivized == "false") {
      encode.sourcing.isIncentivized = false;
    }
    if (query.referrerCandidateChannelUserId) {
      encode.sourcing.referral.referrerCandidateChannelUserId =
        query.referrerCandidateChannelUserId;
    }
    if (query.linkOriginMoment) {
      encode.sourcing.referral.linkOriginMoment = query.linkOriginMoment;
    }
    if (query.extra) {
      eref = eref + "-extra-" + query.extra;
    }
    if (query.referrerCode) {
      encode.sourcing.referral.referrerCode = query.referrerCode;
    }
    if (query.referrerType) {
      encode.sourcing.referral.referrerType = query.referrerType;
    }
    if (query.isIncentivized) {
      encode.sourcing.referral.isIncentivized = query.isIncentivized;
    }
    if (query.is_referral) {
      encode.sourcing.referral.is_referral = query.is_referral;
    }
    if (query.source) {
      encode.sourcing.source = query.source;
    }
    if (query.redirectedFromUrlCategory) {
      encode.sourcing.redirectedFromUrlCategory =
        query.redirectedFromUrlCategory;
    }
    if (query.redirectedFromUrl) {
      encode.sourcing.redirectedFromUrl = query.redirectedFromUrl;
    } else {
      encode.sourcing.redirectedFromUrl = window.location.href;
    }
    if (query.code) {
      merge_to_eref2 = query.code;
    }
    const specialChar = getMobileOperatingSystem() === "Android" ? "?" : "&";
    let tracking_data = {
      listing_id: applyPosition.id,
      listing_type: "job",
      merge_to_eref2: merge_to_eref2,
      reference_v2: encode,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ value: tracking_data }),
    };
    fetch(apiServer + "/encode-data", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const link = `sms:${company.channelConfig.sms.phoneNumber
          }${specialChar}body=${encodeURIComponent(
            formatMessage(messages.jobBoard.channel.message.sms, {
              position: applyPosition.position,
              company: company.company_name,
              code: `epx${data.key}`,
            })
          )}`;
        window.open(
          link,
          //'_blank' // <- This is what makes it open in a new window.
          "_self" // <- This is what makes it open in a same window.
        );
      });
  };
  handleFacebookLink = (company, query, applyPosition) => {
    const page =
      company &&
        company.channelConfig &&
        company.channelConfig.facebook &&
        company.channelConfig.facebook.page
        ? company.channelConfig.facebook.page
        : "holaemitrabajo";
    let eref = "CompanyLanding";
    let encode = {
      sourcing: {
        builder: "career_page",
        redirectedFromUrlCategory: "company landing",
        referral: {},
      },
      application: {
        positionId: applyPosition.positionId
      },
    };
    let merge_to_eref2 = "";
    if (query.builder) {
      encode.sourcing.builder = query.builder;
    }
    if (query.internalCandidate) {
      encode.application.isInternalCandidate = true;
    }
    if (query.job_board) {
      eref = eref + "+" + query.job_board;
      encode.sourcing.jobBoardListingOrigin = query.job_board;
    } else if (query.eref) {
      eref = query.eref;
    }
    if (query && query.campaign) {
      eref = eref + "+" + query.campaign;
      encode.sourcing.campaignName = query.campaign;
    }
    if (query.extra) {
      eref = eref + "-extra-" + query.extra;
    }
    if (query.job_board_listing_id) {
      eref = eref + `-extra-JobBoardListingId+${query.job_board_listing_id}`;
      encode.sourcing.jobBoardListingId = query.job_board_listing_id;
    }
    if (query.referrerCode) {
      encode.sourcing.referral.referrerCode = query.referrerCode;
    }
    if (query.referrerType) {
      encode.sourcing.referral.referrerType = query.referrerType;
    }
    if (query.isIncentivized) {
      encode.sourcing.referral.isIncentivized = query.isIncentivized;
    }
    if (query.is_referral) {
      encode.sourcing.referral.is_referral = query.is_referral;
    }
    if (query.source) {
      encode.sourcing.source = query.source;
    }
    if (query.content) {
      encode.sourcing.content = query.content;
    }
    if (query.redirectedFromUrlCategory) {
      encode.sourcing.redirectedFromUrlCategory =
        query.redirectedFromUrlCategory;
    }
    if (query.redirectedFromUrl) {
      encode.sourcing.redirectedFromUrl = query.redirectedFromUrl;
    } else {
      encode.sourcing.redirectedFromUrl = window.location.href;
    }
    if (query.referrerCandidateChannelUserId) {
      encode.sourcing.referral.referrerCandidateChannelUserId =
        query.referrerCandidateChannelUserId;
    }
    if (query.linkOriginMoment) {
      encode.sourcing.referral.linkOriginMoment = query.linkOriginMoment;
    }
    if (query.code) {
      merge_to_eref2 = query.code;
    }
    let link = "";
    let tracking_data = {
      listing_id: applyPosition.id,
      listing_type: "job",
      merge_to_eref2: merge_to_eref2,
      reference_v2: encode,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ value: tracking_data }),
    };
    fetch(apiServer + "/encode-data", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        link = `https://messenger.com/t/${page}?ref=epx${data.key}`;
        window.open(
          link,
          //'_blank' // <- This is what makes it open in a new window.
          "_self" // <- This is what makes it open in a same window.
        );
      });
  };
  handleWhatsappLink = (company, query, applyPosition) => {
    const { formatMessage } = this.props.intl;
    let eref = "CompanyLanding";
    let encode = {
      sourcing: {
        builder: "career_page",
        redirectedFromUrlCategory: "company landing",
        referral: {},
      },
      application: {
        positionId: applyPosition.positionId
      },
    };
    let merge_to_eref2 = "";
    let eref2 = "";
    if (query.builder) {
      encode.sourcing.builder = query.builder;
    }
    if (query.internalCandidate) {
      encode.application.isInternalCandidate = true;
    }
    if (query.job_board) {
      eref = eref + "+" + query.job_board;
      encode.sourcing.jobBoardListingOrigin = query.job_board;
    } else if (query.eref) {
      eref = query.eref;
    }
    if (query && query.campaign) {
      eref = eref + "+" + query.campaign;
      encode.sourcing.campaignName = query.campaign;
    }
    if (query.extra) {
      eref = eref + "-extra-" + query.extra;
    }
    if (query.referrerCode) {
      encode.sourcing.referral.referrerCode = query.referrerCode;
    }
    if (query.referrerType) {
      encode.sourcing.referral.referrerType = query.referrerType;
    }
    if (query.isIncentivized) {
      encode.sourcing.referral.isIncentivized = query.isIncentivized;
    }
    if (query.is_referral) {
      encode.sourcing.referral.is_referral = query.is_referral;
    }
    if (query.source) {
      encode.sourcing.source = query.source;
    }
    if (query.content) {
      encode.sourcing.content = query.content;
    }
    if (query.redirectedFromUrlCategory) {
      encode.sourcing.redirectedFromUrlCategory =
        query.redirectedFromUrlCategory;
    }
    if (query.redirectedFromUrl) {
      encode.sourcing.redirectedFromUrl = query.redirectedFromUrl;
    } else {
      encode.sourcing.redirectedFromUrl = window.location.href;
    }
    if (query.referrerCandidateChannelUserId) {
      encode.sourcing.referral.referrerCandidateChannelUserId =
        query.referrerCandidateChannelUserId;
    }
    if (query.linkOriginMoment) {
      encode.sourcing.referral.linkOriginMoment = query.linkOriginMoment;
    }
    if (query.code) {
      merge_to_eref2 = query.code;
    }
    let tracking_data = {
      listing_id: applyPosition.id,
      listing_type: "job",
      merge_to_eref2: merge_to_eref2,
      reference_v2: encode,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ value: tracking_data }),
    };
    fetch(apiServer + "/encode-data", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const link = `https://wa.me/${company.channelConfig.whatsapp.phoneNumber
          }?text=${encodeURIComponent(
            formatMessage(messages.jobBoard.channel.message.sms, {
              position: applyPosition.position,
              company: company.company_name,
              code: `epx${data.key}`,
            })
          )}`;
        window.open(
          link,
          //'_blank' // <- This is what makes it open in a new window.
          "_self" // <- This is what makes it open in a same window.
        );
      });
  };

  handleChangeCompany = (company) => {
    this.setState({
      company: endpoints[company],
      companyPath: company,
      listing: null,
      allWorkSchedules: [],
      allBrands: [],
      allPositions: [],
      allLocations: [],
      vacancyListingDict: {},
    })
  }

  render() {
    const { handleWhatsappLink, handleSmsLink, handleFacebookLink, handleChangeCompany, handleCityUpdates } = this
    const { isLoading, listing, company, queryParams,
      allBrands, allPositions, allLocations, allWorkSchedules, companyPath, vacancyListingDict, allStates } = this.state
    let routes = [];
    Object.keys(endpoints).map((endpoint, index) => {
      routes.push(
        <Route
          key={index}
          exact
          path={HOME_PATH + endpoint}
          render={props => <CompanyLanding {...props} changeLanguage={this.props.changeLanguage} handleFacebookLink={handleFacebookLink}
            handleSmsLink={handleSmsLink}
            handleWhatsappLink={handleWhatsappLink}
            handleChangeCompany={handleChangeCompany}
            handleCityUpdates={handleCityUpdates}
            company={company}
            queryParams={queryParams}
            allBrands={allBrands}
            allPositions={allPositions}
            allLocations={allLocations}
            allWorkSchedules={allWorkSchedules}
            companyPath={companyPath}
            listing={listing}
            isLoading={isLoading}
            allStates={allStates} />}
        />
      )
      routes.push(
        <Route
          key={index}
          exact
          path={`${HOME_PATH}${endpoint}/position/:vacancyId`}
          render={props => <CompanySinglePosition {...props} singlePosition={true} companyName = {endpoint} company={company} changeLanguage={this.props.changeLanguage} handleFacebookLink={handleFacebookLink}
            handleSmsLink={handleSmsLink}
            handleWhatsappLink={handleWhatsappLink}
            vacancyListingDict={vacancyListingDict}
            queryParams={queryParams} />}
        />
      )
    })
    routes.push(
      <Route
        exact
        key={-1}
        path={JOB_BOARD_LANDING}
        render={props => <CompanyLanding {...props} changeLanguage={this.props.changeLanguage} />}
      />
    )
    return (
      <BrowserRouter>
        <Switch>
          {routes}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default injectIntl(App);
