/* eslint-disable no-undef */
import React, { Component, Fragment } from "react";
import "./Header.css";
import { emi_logo_intro_white } from "../../img";
import { handleScrollListing } from "../Buttons/handleScroll";
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from './messages';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      referral: false,
      internal: false
    };
  }



  componentDidMount(){
    let { query } = this.props;
    if (query && query.referral){
      this.setState( { referral: query.referral})
    }
    else if (query && query.internal) {
      this.setState( { internal: query.internal})
    }
  }

  handleNameCompany = () => {
    const { formatMessage } = this.props.intl;
    let { referral, internal, company } = this.props;
    let { subsidiary_name } = this.props.Subsidiaries;
    let { company_name } = this.props.Companies;
    let name = company_name === null ? subsidiary_name : company_name;

    // Si el listing_type es igual a referidos cambia el titulo por defecto
    if (referral) {
      if (company && company.referral.headerTitle){
        return (
          <Fragment>{company.referral.headerTitle}</Fragment>
        );
      }
      else {
        return (
          <Fragment>{formatMessage(messages.header.title.referral, {name: name})}</Fragment>
        );
      }
    } else if (internal) {
      return (
        <Fragment>{formatMessage(messages.header.title.internal, {name: name})}</Fragment>
      )
    } 
    else {
      return (
        <Fragment>{formatMessage(messages.header.title.default, {name: name})}</Fragment>
      );
    }
  };

  handleButton = () => {
    const { formatMessage } = this.props.intl;
    let { referral } = this.props;

    // Si el listing_type es igual a referidos cambia el titulo por defecto
    if (referral) {
      return (
        <Fragment>{formatMessage(messages.header.button.referral)}</Fragment>
      );
    }
    else {
      return (
        <Fragment>{formatMessage(messages.header.button.positions)}</Fragment>
      );
    }
  };

  render() {
    // si isLoading es = true significa que todavia no trajo los datos para mostrarlos
    const { formatMessage } = this.props.intl;
    let { isLoading, company, showReferral } = this.props;
    let { company_logo, company_name } = this.props.Companies;
    const { referral } = this.state;

    if (isLoading) {
      // DEFINIR UN TIPO DE CARGA
    }
    return (
      <div className="intro">
        <div className={"intro-text"} style={this.props.style && this.props.style.color? {background: this.props.style.color}:null}>
          <div className="emi_logo_container">
          <img src={emi_logo_intro_white} alt="EMI" className="emi_logo" />
          </div>
          <div>
          <h1 className="landing_title">{this.handleNameCompany()}</h1>
          </div>
          
        </div>
        <div className="intro-logo">
          {/* <!-- company_logo --> */}
          <img src={company_logo} alt="Company Name" className={company_name == "Alsea México" ? "company_logo_alsea" : "company_logo"} />
          {company && company.logo_2 ? (<img src={company.logo_2} alt="Company Name" className="company_logo" />)
          :null}
          
          {/* <!-- END company_logo --> */}
        </div>
        
      </div>
    );
  }
}

export default injectIntl(Header);
