const argv = require('minimist')(process.argv.slice(2));
// the following line is because in internals/webpack/webpack.base.babel.js
// when setting NODE_ENV, if it's set to 'staging' it's not working, bringing
// production. when logging console.log(process.env) it appears: {NODE_ENV: "staging"}
// but when doing console.log(process.env.NODE_ENV) it appears 'production'
// using process.env.ENVIRONMENT fixes it.
const env = process.env.ENVIRONMENT || process.env.NODE_ENV;
const isDev = env !== 'production';
const isStaging = env === 'staging'
// get the intended host and port number, use localhost and port 3000 if not provided
const customHost = argv.host || process.env.HOST;
const host = customHost || null; // Let http.Server use its default IPv6/4 host
const prettyHost = customHost || 'localhost';
const port = argv.port || process.env.PORT || 3000;

// Load different configs for production, qa or development
let configFile = 'prod';

console.log(`config file is: ${configFile}`, env)
export const params = require(`./config.${configFile}.js`);


export const delayLoadingComponentTime = 500;
