import React, { Component, Fragment, useState, useEffect } from "react";
import "../../App.css";
import Listings from "../../components/Listings";
import Referral from "../../components/Referral";
import Referral_V2 from "../../components/Referral_V2";
import LandingRedirection from "../../components/LandingRedirection";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import FooterV2 from "../../components/Footer_V2/Footer";
import HeaderV2 from "../../components/Header_V2/Header";
import ListingsV2 from "../../components/Listings_V2";
import BtnLoad from "../../components/Buttons/BtnLoad";
import { useHistory } from "react-router-dom";



const config = require('../../config');


const cboServer = config.params.CBO_SERVER;
const apiServer = config.params.API_SERVER;

export default ({isLoading, handleWhatsappLink, handleSmsLink, handleFacebookLink, listing, company, queryParams,
      allBrands, allPositions, allLocations, allWorkSchedules, allStates, companyPath, handleChangeCompany, match, handleCityUpdates}) => {

const [showReferral, setShowReferral] = useState(false)
const [referralCode, setReferralCode] = useState('')
const [generatingCode, setGeneratingCode] = useState(false)
const history = useHistory()
  const redirectToCompany = async (company) => {
    await handleChangeCompany(company)
    history.push(`${company}?referral=true`)
  }
  useEffect(() => {
    const path = window.location.pathname.substring(1)
    if (companyPath != path){
      handleChangeCompany(path)
    }
  },[])


  const generateCode = (referralData) => {
    // Simple POST request with a JSON body using fetch
    // const { company } = this.props;
    if (company && company.subsidiaryId){
      referralData.subsidiaryId = company.subsidiaryId
    }
    setGeneratingCode(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: referralData })
  };
  fetch(cboServer+'/encoding', requestOptions)
  // fetch('http://localhost:4000/api/v1/encoding', requestOptions)
      .then(response => response.json())
      .then((data) => {
        
        let isIncentivized = false
        if (company.referral.isIncentivized){
          isIncentivized = company.referral.isIncentivized
        }  
        let encode = {
          sourcing : {
            builder: "referral_page",
            source:"referral",
            redirectedFromUrlCategory: "company landing",
            is_referral:true,
            redirectedFromUrl: window.location.href,
            content: "text link",
            referral :{
              referrerCode: data.code,
              isIncentivized: isIncentivized,
              referrerType: "employee"
            }
          }
        }

        if (company.referral.extraApplicationParameters){
          encode.application = company.referral.extraApplicationParameters
        }  

        if (company.referral.redirect || company.referral.redirectMontemorelos || company.referral.redirectPuebla)  {
          let tracking_data = {
            listing_id : company.referral.redirectListing,
            listing_type :company.referral.redrectListingType,
            reference_v2 : encode
          }
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({value: tracking_data})
          };
  
          return fetch(apiServer+'/encode-data', requestOptions)
          .then(response => {
            return response.json()})
          .then(data =>{
            setReferralCode(data.key)
            setGeneratingCode(false)
          })
        }
        else {
          let utm = `?referrerCode=${data.code}&isIncentivized=${isIncentivized}&referrerType=employee&is_referral=true&builder=referral_page&redirectedFromUrlCategory=company%20landing&source=referral&content=text%20link&redirectedFromUrl=${window.location.href}`
          setReferralCode(utm)
        }
        setGeneratingCode(false)
      })

  }

  const changeToReferral = () => {
    setShowReferral(!showReferral)
  }


    const { path } = match;
    
    return (
      <Fragment>
        {isLoading? (
          company.vacancies ? (
          <>
          <HeaderV2
          isLoading={isLoading}
          Listings={[{}]}
          Companies={company}
          Subsidiaries={[]}
          referral={queryParams.referral}
          style={company.style || {}}
          internal = {queryParams.internalCandidate}
          company={company}
          showReferral={showReferral}
        />
        {queryParams.referral ?(
          company.referral.landingRedirection && !showReferral ? (
            <LandingRedirection
            style={company.style || {}}
            redirections = {company.referral.landingRedirection}
            company = {company}
            changeToReferral = {changeToReferral}
            redirectToCompany = {redirectToCompany}
            
            />
          ) : (
            <Referral_V2
            company={company}
            style={company.style || {}}
            onGenerateLink={generateCode}
            code={referralCode}
            query={queryParams}
            handleChangeCompany={handleChangeCompany}
            companyPath = {companyPath}
            isLoading = {generatingCode}
            />
          )
         
        ):(
          <ListingsV2
            listings={listing? listing : company.listings}
            company={company}
            companySubsidiary = {company.subsidiaryId}
            query={queryParams}
            style={company.style || {}}
            urlNavigation = {path.substring(1)}
            handleFacebookLink = {handleFacebookLink}
            handleSmsLink = {handleSmsLink}
            handleWhatsappLink = {handleWhatsappLink}
            handleCityUpdates = {handleCityUpdates}
            allBrands = {allBrands}
            allLocations = {allLocations}
            allPositions = {allPositions}
            allWorkSchedules = {allWorkSchedules}
            allStates = {allStates}
            />
            )
          }

        <FooterV2
          style={company.style || {}}
          company={company}
        />
        </>
        ) : (
          <>
          <Header
          isLoading={isLoading}
          Listings={[{}]}
          Companies={company}
          Subsidiaries={[]}
          referral={queryParams.referral}
          style={company.style || {}}
          internal = {queryParams.internalCandidate}
          company={company}
          showReferral={showReferral}
        />
        {queryParams.referral ?(
          company.referral.landingRedirection && !showReferral ? (
            <LandingRedirection
            style={company.style || {}}
            redirections = {company.referral.landingRedirection}
            company = {company}
            changeToReferral = {changeToReferral}
            redirectToCompany = {redirectToCompany}
            />
          ) : (
            <Referral
            company={company}
            style={company.style || {}}
            onGenerateLink={generateCode}
            code={referralCode}
            query={queryParams}
            handleChangeCompany = {handleChangeCompany}
            companyPath = {companyPath}
            isLoading = {generatingCode}
            />
          )
          
        ):(
          <Listings
            isLoading={isLoading}
            listings={listing? listing : company.listings}
            company={company}
            query={queryParams}
            style={company.style || {}}
            />)}

        <Footer
          style={company.style || {}}
        />
          </>
        )
        ): <BtnLoad />}
        
      </Fragment>
    );
}


