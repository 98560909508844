import React from "react";

export default ({openCloseModal}) => {
    return (
        <button
                    className="single-position-apply-button"
                    style={{ background: "#2F6DB3" }}
                    value="Facebook"
                    onClick={()=>{openCloseModal("Facebook")}}
                  >
                    Aplicar con Facebook
                    <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/facebook-messenger-white.svg" value = "Facebook"/>
                  </button>
    )
}