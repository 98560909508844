import { animateScroll as scroll, scroller } from "react-scroll";
import { Listing, Section_Help } from "../../strings";

const handleScrollSectionHelp = () => {
  scroller.scrollTo(Section_Help, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart"
  });
};
const handleScrollListing = () => {
  scroller.scrollTo(Listing, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart"
  });
};
export { handleScrollSectionHelp, handleScrollListing };
