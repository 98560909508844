import React, { Component } from "react";
import Router from "./Router";
// Import i18n messages
import {IntlProvider} from 'react-intl';
import translationMessagesES from './translations/es.json';

const locale = "es"; //navigator.language;

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
        locale : locale,
        messages : translationMessagesES
    }
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage(lang){
    switch(lang.toLowerCase())
    {
      case 'en': this.setState({locale:'en'}); this.setState({messages:null}); break;
      default: this.setState({locale:'es'}); this.setState({messages:translationMessagesES}); break;
    }
  }

  render() {

    return (
      <IntlProvider locale ={this.state.locale} messages={this.state.messages}>
        <Router changeLanguage={this.changeLanguage}/>
      </IntlProvider>
    );
  }
}

export default App;
