import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "./Modals.css";
import BtnClose from "../Buttons/BtnClose";
const modalRoot = document.getElementById("modal-root");

class ModalsPoliticaDeConfidencialidad extends Component {
  render() {
    let { show, onClose, onCloseButton } = this.props;
    //Si show es true inserta el modal fade show
    //Show va a ser true cuando se le da click al boton
    //Show solo va a ser falso cuando se carga la pagina por primera vez y cuando se le da click al boton close
    const showHideClassName = show ? "modal fade show" : "modal fade";
    return ReactDOM.createPortal(
      <Fragment>
        <div className={showHideClassName}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Aviso de Privacidad</h5>
                <BtnClose onClose={onCloseButton} />
              </div>
              <div className="modal-body">
                <h6 className="modal-subtitle">¿Quiénes somos?</h6>
                <p>
                  EMI LABS SAS, mejor conocido como Emi, con domicilio en calle
                  AV LIBERTADOR 1002, colonia Vicente Lopez, ciudad Buenos
                  Aires, municipio o delegación Vicente Lopez, c.p. B1638, en la
                  entidad de BUENOS AIRES, país Argentina, y portal de internet
                  info@holaemi.com, es el responsable del uso y protección de
                  sus datos personales, y al respecto le informamos lo
                  siguiente:
                </p>
                <div className="divider" />
                <h6 className="modal-subtitle">
                  ¿Para qué fines utilizaremos sus datos personales?
                </h6>
                <p>
                  Los datos personales que recabamos de usted, los utilizaremos
                  para las siguientes finalidades que son necesarias para el
                  servicio que solicita:
                </p>
                <p>- Crear perfil de candidato a un empleo</p>
                <p>
                  - Contactar candidato en caso de ser seleccionado para la
                  siguiente etapa de un empleo
                </p>
                <p>
                  De manera adicional, utilizaremos su información personal para
                  las siguientes finalidades secundarias que{" "}
                  <strong>no son necesarias</strong> para el servicio
                  solicitado, pero que nos permiten y facilitan brindarle una
                  mejor atención:
                </p>
                <p>
                  - Permitirle a un usuario evitar tener que ingresar datos
                  múltiples veces
                </p>
                <p>
                  En caso de que no desee que sus datos personales se utilicen
                  para estos fines secundarios, indíquelo a continuación:
                </p>
                <p>
                  No consiento que mis datos personales se utilicen para los
                  siguientes fines:
                </p>
                <p>
                  [ ] Permitirle a un usuario evitar tener que ingresar datos
                  múltiples veces{" "}
                </p>
                <p>
                  La negativa para el uso de sus datos personales para estas
                  finalidades no podrá ser un motivo para que le neguemos los
                  servicios y productos que solicita o contrata con nosotros.
                </p>
                <div className="divider" />
                <h6 className="modal-subtitle">
                  ¿Dónde puedo consultar el aviso de privacidad integral?
                </h6>
                <p>
                  Para conocer mayor información sobre los términos y
                  condiciones en que serán tratados sus datos personales, como
                  los terceros con quienes compartimos su información personal y
                  la forma en que podrá ejercer sus derechos ARCO, puede
                  consultar el aviso de privacidad integral en{" "}
                  <a href="https://docs.google.com/document/d/1EokIbSx1ILcBoFSxTsCOuDfSSDK3cZK_F2DmES9ssUQ/edit?usp=sharing">
                    este enlace
                  </a>
                </p>
              </div>
              <div className="modal-footer">
                <span onClick={onClose} className="btn btn-outline">
                  Ver vacantes
                </span>
              </div>
            </div>
          </div>
        </div>
        {}
      </Fragment>,
      modalRoot
    );
  }
}
export default ModalsPoliticaDeConfidencialidad;
