import React, { Component } from "react";
import { Element } from "react-scroll";
import "./JobBoardListing.css";
import "./sticky.css";
import "../../css/normalize.css";
import "../../css/grid.css";
import { Listing } from "../../strings";
import { injectIntl } from 'react-intl';
import messages from './messages';
import ReactMarkdown from 'react-markdown'

import { FaWhatsapp, FaSms, FaFacebookMessenger } from 'react-icons/fa';
const config = require('../../config');


const apiServer = config.params.API_SERVER;


function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollingLock: false,
      copy: false,
      eref2:"",
      filteredListings : [],
      positionDetails: {},
      showInput: true,
      showPositionDetails: false
    };
    this.handleLink = this.handleLink.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.handleSmsLink = this.handleSmsLink.bind(this);
    this.handleWhatsappLink = this.handleWhatsappLink.bind(this);
    this.handleFacebookLink = this.handleFacebookLink.bind(this);
    this.filterListingsFunction = this.filterListingsFunction.bind(this)
  }
  componentDidMount() {
    const {listings, query} = this.props
    const positionDetails = {}
    window.addEventListener("scroll", this.handleScroll)
    if (query.position) {
      // if (query.city){
      //   positionDetails.city = query.city
      // }
      // if (query.salary){
      //   positionDetails.salary = query.salary
      // }
      // if (query.schedule){
      //   positionDetails.schedule = query.schedule
      // }
      this.setState({
        positionDetails: positionDetails,
        filteredListings: listings,
        inputValue: query.position,
        showInput: false,
        showPositionDetails: true
      })
    }
    else{
      this.setState({
        filteredListings : listings
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)

  }
  handleScroll() {
    if (window.scrollY > 400) {
      this.setState({
        scrollingLock: true
      });
    } else if (window.scrollY < 350) {
      this.setState({
        scrollingLock: false
      });
    }
  }
  handleCopy = () => {
    this.setState({ copy: true });
  };
  handleLink = (link) => {
    // window.location.href = link;
    window.open(
      link,
      //'_blank' // <- This is what makes it open in a new window.
      '_self' // <- This is what makes it open in a same window.
    );
  };

  filterListingsFunction =  (evt) => {
    const inputValue = evt.target.value;
    this.setState({
      inputValue: inputValue
    })
    // const listingsArr = this.props.listings.filter(listing => {
    //   return listing.position.toLowerCase().match(inputValue.toLowerCase())
    // })
    // if(listingsArr.length > 1){
    //   this.setState({
    //     positionDetails: {}
    //   })
    // }
  }

  handleSmsLink = (company, listing, query) => {
    const { formatMessage } = this.props.intl;
    let eref = 'CompanyLanding';
    let encode = {sourcing: {
      redirectedFromUrlCategory: "company landing",
      referral: {}
    },
    application: {}
  }
    let merge_to_eref2 = ''
    let eref2 = ''
    if (query.internalCandidate){
      encode.application.isInternalCandidate = true
    }
    if (query.job_board){
      eref = eref + "+" + query.job_board
      encode.sourcing.jobBoardListingOrigin = query.job_board
    } else if (query.eref) {
      eref = query.eref
    }
    if (query && query.campaign){
      eref = eref + "+" + query.campaign
      encode.sourcing.campaignName = query.campaign
    }
    if (query.source){
      // eref = eref + "+" + query.campaign
      encode.sourcing.source = query.source
    }
    if (query.content){
      encode.sourcing.content = query.content
    }
    if (query.isIncentivized == "true"){
      encode.sourcing.isIncentivized = true
    }
    if (query.isIncentivized == "false"){
      encode.sourcing.isIncentivized = false
    }
    if (query.referrerCandidateChannelUserId){
      encode.sourcing.referral.referrerCandidateChannelUserId = query.referrerCandidateChannelUserId
    }
    if (query.linkOriginMoment){
      encode.sourcing.referral.linkOriginMoment = query.linkOriginMoment
    }
    if (query.extra){
      eref = eref + "-extra-"+query.extra
    }
    if (query.referrerCode){
      encode.sourcing.referral.referrerCode = query.referrerCode
    }
    if (query.referrerType){
      encode.sourcing.referral.referrerType = query.referrerType
    }
    if (query.isIncentivized){
      encode.sourcing.referral.isIncentivized = query.isIncentivized
    }
    if (query.is_referral){
      encode.sourcing.referral.is_referral = query.is_referral
    }
    if (query.source){
      encode.sourcing.source = query.source
    }
    if (query.redirectedFromUrlCategory){
      encode.sourcing.redirectedFromUrlCategory = query.redirectedFromUrlCategory
    }
    if (query.redirectedFromUrl){
      encode.sourcing.redirectedFromUrl = query.redirectedFromUrl
    }
    else {
      encode.sourcing.redirectedFromUrl = window.location.href
    }
    if (query.code){
      merge_to_eref2 = query.code
    }
    const specialChar = getMobileOperatingSystem() === 'Android' ? '?' : '&';
      if (company.eref2){
        Object.keys(company.eref2).map(namespace => {
          if (!encode[namespace]) {
            encode[namespace] = {};
          }
          company.eref2[namespace].map(key => {
            encode[namespace][key] = listing[key]
          })
        })
      }
      let tracking_data = {
        listing_id : listing.id,
        listing_type :'job',
        merge_to_eref2: merge_to_eref2,
        reference_v2 : encode
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({value: tracking_data})
      };
      fetch(apiServer+'/encode-data', requestOptions)
      .then(response => {
        return response.json()})
      .then(data => {
        const link = `sms:${company.channelConfig.sms.phoneNumber}${specialChar}body=${
          encodeURIComponent(
            formatMessage(
                messages.jobBoard.channel.message.sms, {
                position: listing.position,
                company: company.company_name,
                code: `epx${data.key}`
              }
            )
          )}`
        window.open(
          link,
          //'_blank' // <- This is what makes it open in a new window.
      '_self' // <- This is what makes it open in a same window.
        );
      })
    
  };
  handleFacebookLink = (company, listing, query) => {
    const { formatMessage } = this.props.intl;
    const page = company
      && company.channelConfig
      && company.channelConfig.facebook
      && company.channelConfig.facebook.page? company.channelConfig.facebook.page : 'holaemitrabajo';
    let eref = 'CompanyLanding';
    let encode = {sourcing: {
      redirectedFromUrlCategory: "company landing",
      referral: {}
    },
    application: {}
  }
    let merge_to_eref2 = ''
    let eref2 = ''
    if (query.internalCandidate){
      encode.application.isInternalCandidate = true
    }
    if (query.job_board){
      eref = eref + "+" + query.job_board
      encode.sourcing.jobBoardListingOrigin = query.job_board
    } else if (query.eref) {
      eref = query.eref
    }
    if (query && query.campaign){
      eref = eref + "+" + query.campaign
      encode.sourcing.campaignName = query.campaign
    }
    if (query.extra){
      eref = eref + "-extra-"+query.extra
    }
    if (query.job_board_listing_id){
      eref = eref + `-extra-JobBoardListingId+${query.job_board_listing_id}`
      encode.sourcing.jobBoardListingId = query.job_board_listing_id
    }
    if (query.referrerCode){
      encode.sourcing.referral.referrerCode = query.referrerCode
    }
    if (query.referrerType){
      encode.sourcing.referral.referrerType = query.referrerType
    }
    if (query.isIncentivized){
      encode.sourcing.referral.isIncentivized = query.isIncentivized
    }
    if (query.is_referral){
      encode.sourcing.referral.is_referral = query.is_referral
    }
    if (query.source){
      encode.sourcing.source = query.source
    }
    if (query.content){
      encode.sourcing.content = query.content
    }
    if (query.redirectedFromUrlCategory){
      encode.sourcing.redirectedFromUrlCategory = query.redirectedFromUrlCategory
    }
    if (query.redirectedFromUrl){
      encode.sourcing.redirectedFromUrl = query.redirectedFromUrl
    }
    else {
      encode.sourcing.redirectedFromUrl = window.location.href
    }
    if (query.referrerCandidateChannelUserId){
      encode.sourcing.referral.referrerCandidateChannelUserId = query.referrerCandidateChannelUserId
    }
    if (query.linkOriginMoment){
      encode.sourcing.referral.linkOriginMoment = query.linkOriginMoment
    }
    if (query.code){
      merge_to_eref2 = query.code
    }
    let link = ''
    if (company.eref2){
      Object.keys(company.eref2).map(namespace => {
        if (!encode[namespace]) {
          encode[namespace] = {};
        }
        company.eref2[namespace].map(key => {
          encode[namespace][key] = listing[key]
        })
      })
    }
    let tracking_data = {
      listing_id : listing.id,
      listing_type :'job',
      merge_to_eref2: merge_to_eref2,
      reference_v2 : encode
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({value: tracking_data})
    };
    fetch(apiServer+'/encode-data', requestOptions)
    .then(response => {
      return response.json()})
    .then(data => {
      link = `https://messenger.com/t/${page}?ref=epx${data.key}`;
      window.open(
        link,
        //'_blank' // <- This is what makes it open in a new window.
      '_self' // <- This is what makes it open in a same window.
      );
    })
  };
  handleWhatsappLink = (company, listing, query) => {
    const { formatMessage } = this.props.intl;
    let eref = 'CompanyLanding';
    let encode = {sourcing: {
      redirectedFromUrlCategory: "company landing",
      redirectedFromUrl: window.location.href,
      referral: {}
    },
      application: {}
    }
    let merge_to_eref2 = ""
    let eref2 = ''
    if (query.internalCandidate){
      encode.application.isInternalCandidate = true
    }
    if (query.job_board){
      eref = eref + "+" + query.job_board
      encode.sourcing.jobBoardListingOrigin = query.job_board
    } else if (query.eref) {
      eref = query.eref
    }
    if (query && query.campaign){
      eref = eref + "+" + query.campaign
      encode.sourcing.campaignName = query.campaign
    }
    if (query.extra){
      eref = eref + "-extra-"+query.extra
    }
    if (query.referrerCode){
      encode.sourcing.referral.referrerCode = query.referrerCode
    }
    if (query.referrerType){
      encode.sourcing.referral.referrerType = query.referrerType
    }
    if (query.isIncentivized){
      encode.sourcing.referral.isIncentivized = query.isIncentivized
    }
    if (query.is_referral){
      encode.sourcing.referral.is_referral = query.is_referral
    }
    if (query.source){
      encode.sourcing.source = query.source
    }
    if (query.content){
      encode.sourcing.content = query.content
    }
    if (query.redirectedFromUrlCategory){
      encode.sourcing.redirectedFromUrlCategory = query.redirectedFromUrlCategory
    }
    if (query.redirectedFromUrl){
      encode.sourcing.redirectedFromUrl = query.redirectedFromUrl
    }
    else {
      encode.sourcing.redirectedFromUrl = window.location.href
    }
    if (query.referrerCandidateChannelUserId){
      encode.sourcing.referral.referrerCandidateChannelUserId = query.referrerCandidateChannelUserId
    }
    if (query.linkOriginMoment){
      encode.sourcing.referral.linkOriginMoment = query.linkOriginMoment
    }
    if (query.code){
      merge_to_eref2 = query.code
    }
    const emiCode = listing.emiCode || 'emipropuesta';
    let code = '';

    if (company.eref2) {
      Object.keys(company.eref2).map(namespace => {
        if (!encode[namespace]) {
          encode[namespace] = {};
        }
        company.eref2[namespace].map(key => {
          encode[namespace][key] = listing[key];
        });
      });
    }

    let listingType = 'job'; // job es el valor predeterminado

    // si emicode es emidemo, listing type es demo
    if (emiCode === 'emidemo') {
      listingType = 'demo';
    }

    let tracking_data = {
      listing_id: listing.id,
      listing_type: listingType, // job o demo
      merge_to_eref2: merge_to_eref2,
      reference_v2: encode
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({value: tracking_data})
    };
    fetch(apiServer+'/encode-data', requestOptions)
    .then(response => {
      return response.json()})
    .then(data => {
      const link = `https://wa.me/${company.channelConfig.whatsapp.phoneNumber}?text=${
        encodeURIComponent(
          formatMessage(
              messages.jobBoard.channel.message.sms, {
              position: listing.position,
              company: company.company_name,
              code: `epx${data.key}`
            }
          )
        )}`
      window.open(
        link,
        //'_blank' // <- This is what makes it open in a new window.
      '_self' // <- This is what makes it open in a same window.
      );
    })
  };

  render() {
    // si isLoading es = true significa que todavia no trajo los datos para mostrarlos
    const { formatMessage } = this.props.intl;
    let { isLoading, company, listings, query } = this.props;
    let {inputValue, positionDetails, showInput, showPositionDetails} = this.state
    let { handleCopy, handleLink, handleWhatsappLink, handleSmsLink, handleFacebookLink,filterListingsFunction } = this;
    const filterListings = listings && inputValue ?  (
      listings.filter(listing => {
        return listing.position.toLowerCase().match(inputValue.toLowerCase())
      }
    ) ) : (listings)
    console.log("FILTER LISTINGS", filterListings)
    return (
      <div className="section">
        <div className="section-listings container-section">
          <Element name={Listing} />
          <h3 className="landing_lead" style={this.props.style && this.props.style.color?{color: this.props.style.color}: {}}>
            {company.landingTitle ? company.landingTitle :  `${formatMessage(messages.jobBoard.listing.title)}:`}
          </h3>
          {company.disclaimer ? (<p className = "disclaimer_text">* {company.disclaimer}</p>) : (null)}
          {showInput ? 
          <div className = "input-container">
          <input type="text" className = "search-listing-input" value={inputValue} placeholder={formatMessage(messages.jobBoard.inputPlaceHolder)} onChange={ filterListingsFunction }></input>
          </div> : null}
          {filterListings? filterListings.map( (listing, index) => {
            let eref2 = ''
            if (!listing) return
            return <div className="listing" key={index}>
              <div className="listing-info">
                <div>
                <h2 className="position_name">{listing.position}</h2>
                </div>
                <div className="position-text">
                  <p className="position_info">{listing.info || listing.description}</p>
                  
                  <div className="position-details">
                    {listing.city ? <div className="position-detail-chip">📍 {listing.city}</div> : null}
                    {listing.total ? <div className="position-detail-chip">💲 {listing.total}</div> : null}
                    {listing.workSchedule ? <div className="position-detail-chip">⏰ {listing.workSchedule}</div> : null}
                  </div>
                </div>
                <div className="position-link">
                  {company && company.channelConfig && company.channelConfig.sms?(
                    <div
                      className="apply-button"
                      onClick={() => handleSmsLink(company, listing, query)}
                      style={this.props.style && this.props.style.color?{backgroundColor: this.props.style.color}: {}}
                    >
                      {formatMessage(messages.jobBoard.button.apply, {channel: "SMS"})} <FaSms className="channel-icon"/>
                    </div>
                  ):null}
                  {company && company.channelConfig && company.channelConfig.whatsapp?(
                    <div
                      className="apply-button"
                      onClick={() => handleWhatsappLink(company, listing, query)}
                      style={this.props.style && this.props.style.color?{backgroundColor: this.props.style.color}: {}}
                    >
                      {formatMessage(messages.jobBoard.button.apply, {channel: "WhatsApp"})} <FaWhatsapp className="channel-icon"/>
                    </div>
                  ):null}
                  {company && company.channelConfig && company.channelConfig.facebook?(
                    <div
                    className="apply-button"
                    onClick={() => handleFacebookLink(company, listing, query)}
                    style={this.props.style && this.props.style.color?{backgroundColor: this.props.style.color}: {}}
                  >
                    {formatMessage(messages.jobBoard.button.apply, {channel: "Facebook"})} <FaFacebookMessenger className="channel-icon"/>
                  </div>
                  ):null}
                </div>


              </div>
              {company && company.channelConfig && company.channelConfig.sms? (
                <p style={{padding: '20px', fontSize: '10px'}}>By using the Apply with SMS option you are consenting to receive text messages to your phone about this position. To unsubscribe at any time, reply to any SMS sent by Emi Labs with the word STOP or contact Emi Labs Customer Support at <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#101;&#109;&#105;&#108;&#97;&#98;&#115;&#46;&#97;&#105;">&#105;&#110;&#102;&#111;&#64;&#101;&#109;&#105;&#108;&#97;&#98;&#115;&#46;&#97;&#105;</a>. Replying STOP in response to an SMS sent by Emi will unsubscribe you from all further SMS messages from Emi</p>
              ):null}

            </div>
          }):null}


        </div>
        {/* <!-- END LISTING --> */}
      </div>
    );
  }
}

export default injectIntl(Listings);

