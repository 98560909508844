/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  jobBoard: {
    listing: {
      title: {
        id: 'jobBoard.listing.title',
        defaultMessage: 'Click to apply through your desired channel'
      },
      positionHelp: {
        title: {
          id: 'jobBoard.listing.positionHelp.title',
          defaultMessage: 'If you have any problem'
        },
        sms: {
          id: 'jobBoard.listing.positionHelp.sms',
          defaultMessage: 'Text to apply to the number {number}.'
        },
        facebook: {
          id: 'jobBoard.listing.positionHelp.facebook',
          defaultMessage: 'Copy and paste this link in your browser {link}.'
        },
        whatsapp: {
          id: 'jobBoard.listing.positionHelp.whatsapp',
          defaultMessage: 'Send a WhatsApp message to the number {number}.'
        }
      }
    },
    button: {
      apply: {
        id: 'jobBoard.button.apply',
        defaultMessage: 'Apply with {channel}'
      }
    },
    channel: {
      message: {
        sms: {
          id: 'jobBoard.channel.message.sms',
          defaultMessage: "Hi! to begin your application hit the enter button of this message! (the following code is required to continue) - {code}"
        },
        whatsapp: {
          id: 'jobBoard.channel.message.whatsapp',
          defaultMessage: "Hi! to begin your application hit the enter button of this message! (the following code is required to continue) - {code}"
        }
      }

    },
    inputPlaceHolder: {
      id: 'jobBoard.inputPlaceHolder',
      defaultMessage: "Search for a position"
    }
  }
});
