export const CBO_SERVER =
  process.env.CBO_SERVER || 'https://cbo.emilabs.ai/api/v1';

  export const API_SERVER =
  process.env.API_SERVER || 'https://api.emilabs.ai/shortener/v1';

  export const VACANCIES =
  process.env.VACANCIES_SERVER || 'https://api.emilabs.ai/job-postings/v1/career-pages';

  export const SUBSIDIARY_SERVER =
  process.env.SUBSIDIARY_SERVER || 'https://api.emilabs.ai/company/v1/subsidiaries';
