import React, { Component } from "react";
import { Element } from "react-scroll";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./referrals.css";
import "./sticky.css";
import "../../css/normalize.css";
import "../../css/grid.css";
import BtnLoad from "../Buttons/BtnLoad";
import { handleScrollSectionHelp } from "../Buttons/handleScroll";
import { Listing } from "../../strings";
import { injectIntl } from 'react-intl';
import messages from './messages';
import Links from './links'
import ReactMarkdown from 'react-markdown'


class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referralData: {},
      errorFields: false,
      scrollingLock: false,
      copy: false
    };
    this.handleLink = this.handleLink.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    if (window.scrollY > 400) {
      this.setState({
        scrollingLock: true
      });
    } else if (window.scrollY < 350) {
      this.setState({
        scrollingLock: false
      });
    }
  }
  handleCopy = () => {
    this.setState({ copy: true });
  };
  handleLink = (link) => {
    // window.location.href = link;
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );
  };

  generateLink = () => {
    const { referralData } = this.state;
    const { company } = this.props;
    let error = false;
    company.referral.employeeFields.map(k => {
      error = error || !referralData[k.name]
    })
    if (company.subsidiaryId == 67 && !referralData.facility) {
      this.setState({ errorFields: true })
    } else if (error) {
      this.setState({ errorFields: error })
    } else if (this.props.onGenerateLink) {
      this.setState({ errorFields: false })
      this.props.onGenerateLink(referralData)
    } else {
      this.setState({ errorFields: false })
    }
  }

  onInputchange = (event) => {
    const { referralData } = this.state
    referralData[event.target.name] = event.target.value
    this.setState({ referralData });
  }


  render() {
    // si isLoading es = true significa que todavia no trajo los datos para mostrarlos
    const { formatMessage } = this.props.intl;
    let { isLoading, company, listings, query } = this.props;
    const { errorFields } = this.state;


    const hasLocationDropwown = company && company.subsidiary_locations && company.subsidiary_locations.locations && company.subsidiary_locations.locations.filter(l => l.referrals).length > 0

    return (
      <div className="section">
        <div className="section-listings container-section">
          <Element name={Listing} />
          <h2 className="referral_lead" style={this.props.style && this.props.style.color ? { color: this.props.style.color } : {}}>
            {company.referral.title}
          </h2>

          <div className="listing">
            <div className="referral-info">

              <div style={{ textAlign: 'center', maginTop: '20px' }}>
                <h4>
                  {company.referral.benefits}
                </h4>
                {company.referral.benefitsSubtitle ? <h4>{company.referral.benefitsSubtitle}</h4> : null}
              </div>
              <div className="referral-text">
                <ReactMarkdown className="referral_info" children={company.referral.instructions} />


                {isLoading ? (<BtnLoad />) : (
                  this.props.code ? (
                    <Links
                      code={this.props.code}
                      query={this.props.query}
                      company={company}
                      referralData={this.state.referralData}
                    />
                  ) : (
                    <div>
                      <div className='container'>
                        {company.referral.instructionsImage ? <div className="instructions-image-container"><img src={company.referral.instructionsImage} /></div> : null}
                        <div className="row">
                          <div className="col-sm-12 col-md-4"></div>
                          <div className="col-sm-12 col-md-4">

                            {company.referral.employeeFields.map(k => {
                              const fields = company.referral.employeeFields
                              return <div key={k.name} className="">
                                {k.dropdown ? (
                                  <label style={{ width: "100%" }}>
                                    {k.label}
                                    <select className="form-control" name={k.name} onChange={this.onInputchange}>
                                      <option name=""></option>
                                      {k.dropdown.map(e => {
                                        return <option name={e.value} key={e.key}>{e.value}</option>
                                      })}
                                    </select>
                                  </label>
                                ) : (
                                  <input
                                    className="form-control"
                                    type={k.onlyNumbers ? "number" : "text"} id={k.name}
                                    name={k.name}
                                    placeholder={k.label}
                                    style={{ marginTop: '20px' }}
                                    onChange={this.onInputchange}
                                    maxLength={k.maxLength ? k.maxLength : false}
                                  />
                                )}

                              </div>

                            })}
                          </div>
                          <div className="col-sm-12 col-md-4"></div>
                        </div>
                      </div>

                      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        {company.referral.generateLinkMessage ? <div>{company.referral.generateLinkMessage}</div> : null}
                        <button
                          onClick={() => this.generateLink()}
                          type="button"
                          className="emi-btn emi-btn-outline-light"
                          style={this.props.style && this.props.style.color ? { backgroundColor: this.props.style.color } : {}}
                        >
                          {formatMessage(messages.referral.button.generateLink)}
                        </button>
                        {errorFields ? (
                          <div style={{ color: 'red' }}>{formatMessage(messages.referral.error.fields)}</div>
                        ) : (null)}
                      </div>
                      <div className="referral_conditions_container">
                        <ReactMarkdown className="referral_conditions">{company.referral.conditions}</ReactMarkdown>
                      </div>
                    </div>
                  )
                )}

              </div>


            </div>


          </div>
        </div>
        {/* <!-- END LISTING --> */}
      </div>
    );
  }
}

export default injectIntl(Referral);
