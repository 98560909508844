import React, { Component } from "react";
import { Element } from "react-scroll";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./referrals.css";
import "./sticky.css";
import "../../css/normalize.css";
import "../../css/grid.css";
import BtnLoad from "../Buttons/BtnLoad";
import { handleScrollSectionHelp } from "../Buttons/handleScroll";
import { Listing } from "../../strings";
import { injectIntl } from 'react-intl';
import messages from './messages';
import {InlineShareButtons} from 'sharethis-reactjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FaWhatsapp, FaSms, FaFacebookMessenger } from 'react-icons/fa';

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

class ReferralLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);

  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    if (window.scrollY > 400) {
      this.setState({
        scrollingLock: true
      });
    } else if (window.scrollY < 350) {
      this.setState({
        scrollingLock: false
      });
    }
  }
  handleCopy = () => {
    this.setState({ copy: true });
    this.notify();
  };
  notify = () => {
    const { formatMessage } = this.props.intl;
    toast(formatMessage(messages.referral.button.copied))
  };

  buildLink = () => {
    let link = ''
      if (this.props.company.referral.redirect){
        link = this.props.company.referral.redirect+`epx${this.props.code}` // epx...
      }
      else {
        link = window.location.href.split('?')[0]+`${this.props.code}`
      }
      
    return link
  }

  render() {
    // si isLoading es = true significa que todavia no trajo los datos para mostrarlos
    const { formatMessage } = this.props.intl;
    let { handleCopy } = this;
    const link = this.buildLink();

    return (
      <div className="section" style={{padding: '20px', textAlign: 'center'}}>
        <div>{formatMessage(messages.referral.links.link)}:</div>
        <CopyToClipboard text={link} onCopy={handleCopy}>
          <button type="button" className="emi-btn-clipboard">&nbsp;{link}</button>
        </CopyToClipboard>
        {/* {link.includes('jobs.emilabs.ai') ? (
          <>
          <div style={{margin: '20px'}}>{formatMessage(messages.referral.links.title)}: </div>
          <InlineShareButtons
          config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            color: 'white',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 14,        // font size for the buttons
            labels: 'null',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'whatsapp',
              'messenger',
              'wechat',
              'sms',
            ],
            padding: 20,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: false,
            size: 60,             // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
            url: `${link}`, // (defaults to current url)
            message: `${link}`
          }}
        />
        </>
        ) : null} */}
          

          {/* <div className="position_link-help">
            {formatMessage(messages.referral.links.copy)} &nbsp;&nbsp;
            <CopyToClipboard text={link} onCopy={handleCopy}>
              <button type="button" className="emi-btn-clipboard">&nbsp;{link}</button>
            </CopyToClipboard>
          </div> */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            />


      </div>
    );
  }
}

export default injectIntl(ReferralLinks);
