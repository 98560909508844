import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "./Modals.css";
import BtnClose from "../Buttons/BtnClose";
const modalRoot = document.getElementById("modal-root");

class ModalsPreguntasFrecuentes extends Component {
  render() {
    let { show, onClose, onCloseButton } = this.props;
    //Si show es true inserta el modal fade show
    //Show va a ser true cuando se le da click al boton
    //Show solo va a ser falso cuando se carga la pagina por primera vez y cuando se le da click al boton close
    const showHideClassName = show ? "modal fade show" : "modal fade";
    return ReactDOM.createPortal(
      <Fragment>
        <div className={showHideClassName}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="alert">
                  <h6>Hola!</h6>
                  <p>
                    Si tenés alguna duda acerca de cómo postularte o cómo chatear con Emi, leé nuestras Preguntas Frecuentes:
                  </p>
                </div>
                <BtnClose onClose={onCloseButton} />
              </div>
              <div className="modal-body">
                <h5 className="modal-title">Preguntas Frecuentes</h5>
                <h6 className="modal-subtitle">No tengo Messenger pero sí tengo Facebook, ¿qué puedo hacer?</h6>
                <p>Ingresando desde una computadora a tu cuenta de Facebook vas a poder acceder a Messenger para hablar con Emi. Solo tenés que hacer click en el ícono de Messenger que aparece arriba a la derecha en tu sesión de Facebook (es un ícono de burbuja de conversación).</p>
                <div className="divider" />
                <h6 className="modal-subtitle">No tengo Facebook ni Messenger, ¿cómo hago para postularme?</h6>
                <p>Para hablar con Emi necesitás descargar la App de Messenger. Únicamente toma 5 minutos! No hace falta que te armes una cuenta en Facebook, podés registrarte directamente con tu número de teléfono siguiendo los siguientes pasos:</p>
								<p>1. Descarga la aplicación Messenger desde Google Play Store (si tenés un celular Android) o App Store (iOS)</p>
								<p>2. Abre la aplicación Messenger, escribe tu número de teléfono y hacé click en Continuar</p>
								<p>3. Una vez que confirmes el número de teléfono, agrega tu nombre y una foto y podrás comenzar a usar la aplicación</p>
                <div className="divider" />
                <h6 className="modal-subtitle">Ya contesté todas las preguntas de Emi, ¿ahora qué pasa?</h6>
                <p>La empresa va a recibir tu información y analizar tu postulación. Emi te va a avisar a través del chat en cuanto tenga novedades, tanto en el caso de avanzar a la siguiente etapa o de no continuar en el proceso. Puede demorar unos días, no es inmediato. Tené paciencia!</p>
                <div className="divider" />
                <h6 className="modal-subtitle">¿Me puedo postular a más de 1 puesto de la empresa?</h6>
                <p>Sí! Podés postularte a todas las vacantes de la empresa que quieras. Para cada postulación serás evaluado en forma independiente, de acuerdo a los requisitos solicitados para ese puesto.</p>
                <div className="divider" />
                <h6 className="modal-subtitle">Cuando entro al chat, Emi no me hace ninguna pregunta o me tira error</h6>
                <p>Haciendo click nuevamente en el botón Aplicar que aparece en la página o copiando el link de la búsqueda en Google Chrome, podrás retomar el chat con Emi.</p>
								<p>Otra opción es que en el chat con Emi escribas el código de la búsqueda a la que estás aplicando. El código siempre es “emipropuestaXXXX” (todo junto, sin espacios). Podés encontrar el código de búsqueda adentro del link, por ejemplo si el link es m.me/holaemitrabajo?ref=emipropuesta1234-eref-link, el código de búsqueda será emipropuesta1234.</p>
								<div className="divider" />
                <h6 className="modal-subtitle">Me interesa otro puesto de la empresa que no está en la página, ¿qué hago en ese caso?</h6>
								<p>Si hay otro puesto en la empresa al que te gustaría postularte pero no aparece en esta página, podés dejarle tus datos a la empresa a través de su portal de empleos.</p>
              </div>
              <div className="modal-footer">
                <span onClick={onClose} className="btn btn-outline">
                  Ver vacantes
                </span>
              </div>
            </div>
          </div>
        </div>
      </Fragment>,
      modalRoot
    );
  }
}

export default ModalsPreguntasFrecuentes;
