import React, { Component} from "react";
import "../../App.css";

import FooterV2 from "../../components/Footer_V2/Footer";
import HeaderEmi from "../../components/Header_V2/HeaderEmi";
import SinglePositionComponent from "../../components/SinglePosition";
import BtnLoad from "../../components/Buttons/BtnLoad";

const config = require('../../config');

const vacanciesServer = config.params.VACANCIES

class SinglePosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      generatingCode: false,
      listing: null,
      company: {},
      showReferral: false,
      modalOpened: false,
      applicationChannel: "",
      applyPosition: {},
      width: window.innerWidth,
    };
    this.openCloseModal = this.openCloseModal.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this)
  }
  

  

  async componentDidMount() {
    const { vacancyId } = this.props.match.params;
    const {vacancyListingDict} = this.props
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.handleWindowSizeChange)

      try {
    const result = await fetch(`${vacanciesServer}/posting/${vacancyId}` )
    const position = await result.json()
    position.postingId = position.id
    if (vacancyListingDict[position.postingId]){
      position.id = vacancyListingDict[position.postingId].positionListingId
      position.description = vacancyListingDict[position.postingId].positionDescription
    }

    position.emiCode = "emipropuesta"
    
    let tagBrand = position.tags && position.tags.filter(tag => tag.name === "brand") || []
    let brandsDict = tagBrand.length && tagBrand[0].value || ''
    let brands = brandsDict && brandsDict.map(brand => brand.slice(0,1) + brand.slice(1).toLowerCase()) || []
    position.brand = brands.length && brands.join(", ") || ''

    this.setState({
      applyPosition: position
    })
  }
  catch(e) {
    console.log(e)
  }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth });
};


  openCloseModal = (channel) => {
    this.setState({
      modalOpened: !this.state.modalOpened,
      applicationChannel: channel,
    });
  };

  render() {
    const {
      modalOpened,
      applicationChannel,
      applyPosition,
    } = this.state;
    const {
      company,
      handleFacebookLink,
      handleWhatsappLink,
      singlePosition,
      vacancyListingDict,
      queryParams,
      companyName
    } = this.props;
    let { openCloseModal } = this;

    const { width } = this.state;

    const isMobile = width <= 700;

    return (
      <>
        <HeaderEmi
          companyLogo={company.company_logo}
          singlePosition={singlePosition}
          companyName={companyName}
          query={queryParams}
          isMobile = {isMobile}
          company = {company}
          style={company.style || {}}
          linkGoBack = {company.linkGoBack}
        />
        {vacancyListingDict[applyPosition.postingId] ? 
        <SinglePositionComponent company = {company} applyPosition = {{...applyPosition, description: vacancyListingDict[applyPosition.postingId].positionDescription, id: vacancyListingDict[applyPosition.postingId].positionListingId}}
        openCloseModal = {openCloseModal} handleFacebookLink = {handleFacebookLink}
        handleWhatsappLink = {handleWhatsappLink} applicationChannel = {applicationChannel}
        modalOpened = {modalOpened} queryParams = {queryParams} style = {company.style}/> : <BtnLoad/>}
        <FooterV2 company={company} style = {company.style} />
      </>
    );
  }
}
export default SinglePosition;
