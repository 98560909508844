import React from "react";

export default ({openCloseModal}) => {
    return (
        <button
                    className="single-position-apply-button"
                    style={{ background: "#128C7E" }}
                    value="Whatsapp"
                    onClick={()=>{openCloseModal("Whatsapp")}}
                  >
                    Aplicar con Whatsapp
                    <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/whatsapp-white.svg" value = "Whatsapp"/>
                  </button>
    )
}