import React from "react";
import "./BtnClose.css";
import logo_close from "../../img/close.svg";

const BtnClose = props => {
  return (
    <button className="button-logo-close" onClick={props.onClose}>
      <img alt="logo-close" className="logo-close" src={logo_close} />
    </button>
  );
};

export default BtnClose;
