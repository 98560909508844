import React from "react";

let workSchedulesDict = {
  FULL_TIME: "Tiempo completo",
  PART_TIME: "Tiempo parcial",
};

export default ({ value, onChange, title, elements, dropdownEnabled, enableDropdown }) => {
  return (
    <div className="dropdown-sub-filter">
      <div className="select-selected" onClick={enableDropdown}>
        <div className="city-dropdown-filter-element-title">
          {workSchedulesDict[value]
            ? workSchedulesDict[value]
            : title}
        </div>
        <div>
          <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/arrow-down-2.svg" />
        </div>
      </div>
      <div className={`select-items ${!dropdownEnabled ? "select-hide" : null}`}>
        <div className="city-dropdown-filter-element-option"
          onClick={() => onChange("")}
        >

        </div>
        {elements && elements.map((workSchedule) => {
          return (
            <div key={workSchedule} className="city-dropdown-filter-element-option"
              onClick={() => onChange(workSchedule)}
            >
              {workSchedulesDict[workSchedule]
                ? workSchedulesDict[workSchedule]
                : workSchedule}
            </div>
          );

        })}
      </div>
    </div>
  )
}

// export default ({value, onChange, title, elements}) => {
//     return (
//         <div className="positions-section-dropdown-filter">
//                       <select
//                         className="positions-section-dropdown-filter-element"
//                         value={value}
//                         onChange={onChange}
//                         name="selectedSchedule"
//                       >
//                         <option disabled={true} value="">
//                           {title}
//                         </option>
//                         <option value=""></option>
//                         {elements &&
//                           elements.map((workSchedule) => {
//                             return (
//                               <option key={workSchedule} value={workSchedule}>
//                                 {workSchedulesDict[workSchedule]
//                                   ? workSchedulesDict[workSchedule]
//                                   : workSchedule}
//                               </option>
//                             );
//                           })}
//                       </select>
//                     </div>
//     )
// }