/* eslint-disable no-undef */
import React, { Component, Fragment } from "react";
import "./Header.css";
import { emi_logo_intro_white } from "../../img";
import { handleScrollListing } from "../Buttons/handleScroll";
import { injectIntl, FormattedMessage } from "react-intl";
import messages from "./messages";
import HeaderEmi from "./HeaderEmi";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internal: false,
      width: window.innerWidth,
    };
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this)
  }

  componentDidMount() {
    let { query } = this.props;
    window.addEventListener('resize', this.handleWindowSizeChange);
    // if (query && query.referral) {
    //   this.setState({ referral: query.referral });
    // } else if (query && query.internal) {
    //   this.setState({ internal: query.internal });
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleNameCompany = () => {
    const { formatMessage } = this.props.intl;
    let { referral, internal, company } = this.props;
    let { subsidiary_name } = this.props.Subsidiaries;
    let { company_name } = this.props.Companies;
    let name = company_name === null ? subsidiary_name : company_name;

    // Si el listing_type es igual a referidos cambia el titulo por defecto
    if (referral) {
      if (company && company.referral.headerTitle) {
        return <Fragment>{company.referral.headerTitle}</Fragment>;
      } else {
        return (
          <Fragment>
            {formatMessage(messages.header.title.referral, { name: name })}
          </Fragment>
        );
      }
    } else if (internal) {
      return (
        <Fragment>
          {formatMessage(messages.header.title.internal, { name: name })}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {formatMessage(messages.header.title.default, { name: name })}
        </Fragment>
      );
    }
  };

  handleButton = () => {
    const { formatMessage } = this.props.intl;
    let { referral } = this.props;

    // Si el listing_type es igual a referidos cambia el titulo por defecto
    if (referral) {
      return (
        <Fragment>{formatMessage(messages.header.button.referral)}</Fragment>
      );
    } else {
      return (
        <Fragment>{formatMessage(messages.header.button.positions)}</Fragment>
      );
    }
  };

  render() {
    // si isLoading es = true significa que todavia no trajo los datos para mostrarlos
    const { formatMessage } = this.props.intl;
    let { isLoading, company, showReferral, singlePosition, referral, style } = this.props;
    let { company_logo, company_name } = this.props.Companies;
    const { width } = this.state;

    const isMobile = width <= 700;

    if (isLoading) {
      // DEFINIR UN TIPO DE CARGA
    }
    return (
      <div>
        <HeaderEmi companyLogo={company_logo} isMobile={isMobile} style = {style} company={company} />
        <div className="client-banner-container" >
          <img
            src={isMobile ? referral ? company.referral.company_banner_mobile : company.company_banner_mobile : referral ? company.referral.company_banner : company.company_banner}
            alt="Company Banner"
            className="client-banner"
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(Header);
