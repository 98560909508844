/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  footer: {
    rights: {
      id: 'footer.rights',
      defaultMessage: 'Emi Labs © {year} | All rights reserved.'
    },
    moreInfo: {
      title: {
        id: 'footer.moreInfo.title',
        defaultMessage: 'More Information'
      },
      privacy: {
        id: 'footer.moreInfo.privacy',
        defaultMessage: 'Privacy Policy'
      },
      faq: {
        id: 'footer.moreInfo.faq',
        defaultMessage: 'Frequently asked questions'
      }
    }
  }
});
