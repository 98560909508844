import React, { Component } from "react";
import "./landingRedirect.css";
import "./sticky.css";
import "../../css/normalize.css";
import "../../css/grid.css";
import { injectIntl } from "react-intl";

export default ({ company, changeToReferral, redirectToCompany }) => {  
  
return (
      <div className="section">
        <div className="section-listings container-section">
          <div className="listing">
            <div className="landing-redirect-main-redirect">
              <h2 className="landing-redirect-title">
                Programa de <span style={{ color: "#50C8E7" }}>Referidos</span>{" "}
                de Alsea
              </h2>
              <h3 className="landing-redirect-description">
                Selecciona una marca y se te redigirá a la página donde podrás
                completar tus datos y generar la liga para compartir
              </h3>
              <div className="landing-redirect-division-line">
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#0083BF" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#0A764D" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#F48533" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#8A2C60" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#5D9845" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#393B38" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#EE3543" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#E8178E" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#E4B122" }}
                />
                <hr
                  className="landing-redirect-division-line-section"
                  style={{ background: "#0282C2" }}
                />
              </div>
              <h2 className="landing-redirect-second-title">
                ¿A qué marca deseas referir?
              </h2>
            </div>
            <div className="landing-redirect-secondary-redirects">
              {company &&
                company.referral &&
                company.referral.landingRedirection.map((redirect) => {
                  return (
                    <div className="landing-redirect-secondary-card-container">
                      <button
                      onClick = {()=>{redirectToCompany(redirect.application
                          .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "")
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toLowerCase())
                        }}
                        className="landing-redirect-secondary-card-link"
                      >
                        <div className="landing-redirect-secondary-card">
                          <div
                            className="landing-redirect-logo-container"
                            style={{ borderColor: redirect.borderColor }}
                          >
                            <img
                              src={redirect.logo}
                              alt={redirect.application
                                .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "")
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")}
                              className="landing-redirect-logo"
                            />
                          </div>
                          <div className="landing-redirect-card-title-container">
                            <h2 className="landing-redirect-card-title">
                              {redirect.application}
                            </h2>
                          </div>
                        </div>
                      </button>
                    </div>
                  );
                })}
              <div className="landing-redirect-main-card-container">
                <button
                  className="landing-redirect-main-card-button"
                  onClick={changeToReferral}
                >
                  <div className="landing-redirect-main-card">
                    <div
                      className="landing-redirect-logo-container"
                      style={{ borderColor: "#025483" }}
                    >
                      <img
                        src={company && company.company_logo}
                        alt="Company Name"
                        className="landing-redirect-logo"
                      />
                    </div>
                    <div className="landing-redirect-card-title-container">
                      <h2
                        className="landing-redirect-card-title"
                        style={{ color: "#025483" }}
                      >
                        Alsea
                      </h2>
                      <p className="landing-redirect-card-text">
                        Refiere a todas nuestras marcas
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- END LISTING --> */}
      </div>
    );
  }

